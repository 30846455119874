import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./hooks/useAuth";

import CookieConsentBanner from "./layout/protected-routes/CookieConsentBanner";
import AnonymousRoute from "./layout/protected-routes/AnonymousRoute";
import VerifyOtp from "./views/pages/VerifyOtp/VerifyOtp";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
//ForgetPassword
const ForgetPassword = React.lazy(() =>
  import("./views/pages/forgetPassword/ForgetPassword")
);
const VerifyEmail = React.lazy(() =>
  import("./views/pages/verifyEmail/VerifyEmail")
);
// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Success = React.lazy(() => import("./views/pages/Success"));
const InvitationAcceptance = React.lazy(() =>
  import("./views/pages/invitationAcceptance")
);

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route element={<AnonymousRoute />}>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />
            </Route>
            <Route element={<CookieConsentBanner />}>
              <Route exact path="*" name="Home" element={<DefaultLayout />} />
            </Route>
            <Route
              exact
              path="/forget-password"
              name="Forget Password"
              element={<ForgetPassword />}
            />
            <Route
              exact
              path="/verify-email"
              name="Verify Email"
              element={<VerifyEmail />}
            />

            <Route
              exact
              path="/verify-otp"
              name="Verify Otp"
              element={<VerifyOtp />}
            />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/success" name="Success" element={<Success />} />
            <Route
              exact
              path="/invitation-acceptance"
              name="Invitation Acceptance"
              element={<InvitationAcceptance />}
            />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
