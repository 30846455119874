import { CToast, CToastBody, CToastClose, CToaster, CToastHeader } from "@coreui/react";
import { useEffect, useState } from "react";

export const Toaster = ({isTriggerd, mssg_title, toastr_type}) => {

    const [toast, addToast] = useState(0);
  
    useEffect(() => {
      if(isTriggerd) {
        addToast(ToasterBody)
      }
    },[isTriggerd]);
  
    const ToasterBody = (
      <CToast color={toastr_type} className="text-white align-items-center">
        <div className="d-flex">
          <CToastBody>{mssg_title}</CToastBody>
          <CToastClose className="me-2 m-auto" white />
        </div>
      </CToast>
    );
  
    return (
      <>
        <CToaster push={toast} placement="top-end" />
      </>
    )
  }