import { CCard, CCardBody } from "@coreui/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { authAxios } from "src/helper/axios-instance/authAxios";
import { useAuth } from "src/hooks/useAuth";
import { Toaster } from "src/components/common/Toastr";

const VerifyOtp = ({ emailAddress, success, setSuccess, handleResendOtp, otp, setOtp}) => {
  // OTP state to store the input values
  const [isDisabled, setIsDisabled] = useState(false); // For disabling the resend button
  const [timer, setTimer] = useState(30); // Timer for resend button
  const [errorMssg, setErrorMssg] = useState("");

  const { login } = useAuth();

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Allow only numbers

    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    // Automatically move to the next input field if current field is filled
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  // Handle OTP submit
  const handleOtpSubmit = async() => {
    const otpCode = otp.join("");
    if (otpCode.length === 6) {
      // payload
      const payload = {
        email_id: emailAddress,
        login_otp: otpCode,
      };
      console.log("OTP Submitted:", payload);

      try {
       await authAxios.post("/verify/otp", payload).then((response) => {
          if (response?.data?.success) {
            console.log("OTP Verified Successfully");
            setSuccess(true);
            localStorage.setItem("access_token", response.data.data.access_token);
            Cookies.set("access_token", response.data.data.access_token);
            Cookies.set("access_token", response.data.data.refresh_token);
            localStorage.setItem(
              "refresh_token",
              response.data.data.refresh_token
            );
            login(response.data);
          }
        });
      } catch (error) {
        setErrorMssg({ error: error?.response?.data?.message });
      }
    } else {
      alert("Please enter the 6-digit OTP");
    }
  };

  useEffect(() => {
    if (timer !== 30) return;
  }, [timer]);

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text').trim();
    if (pasteData.length === 6 && /^[0-9]{6}$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
    }
  };

  return (
    <>
    <Toaster
      isTriggerd={errorMssg}
      mssg_title={errorMssg?.error || errorMssg?.success}
      toastr_type={errorMssg?.success ? "success" : "danger"}
    />
      <CCard className="p-4">
        <CCardBody>
          <div className="otp-verify text-center">
            <h1 className="card-title mb-4">Verify Your OTP</h1>
            {/* Ensure email is rendered as a string */}
            <h5 className="mb-3">
              Enter 6-digit verification code sent to your email address{" "}
              <span className="text-primary">{emailAddress}</span>
            </h5>

            <p style={{ fontSize: "20px", fontWeight: 600 }} className="mb-3">
              Enter Code Here{" "}
            </p>
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              {otp.map((digit, index) => (
                <>
                  <input
                    key={index}
                    className="m-2 text-center form-control rounded"
                    type="text"
                    id={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    maxLength="1"
                    onFocus={(e) => e.target.select()} // Automatically select content when focusing
                    onPaste={handleOtpPaste}
                  />
                </>
              ))}
            </div>

            <div
              className="btn btn-primary w-100 my-3 custom-btns"
              onClick={handleOtpSubmit}
            >
              Submit
            </div>
            <div
              className="btn btn-light w-100 custom-btns"
              onClick={handleResendOtp}
              disabled={isDisabled}
            >
              {isDisabled ? `Resend in ${timer}s` : "Resend"}
            </div>
          </div>
        </CCardBody>
      </CCard>
    </>
  );
};

export default VerifyOtp;
